import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class CustInvoiceFormAdapter extends CerAppFormModelAdapter {

  override init() {


    var isDraft: boolean = this.routeParams.filter(p => p == '$status=Draft').length > 0;
    this.detailPaneVertical = true;
    this.detailPaneSizePct = 50;
    this.viewMetadata = {
      name: 'salgs-fakturaer', dataApiName: 'CustInvoice', text: 'Fakturaer', textSingular: 'Faktura',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history'], 
      allowCreate: isDraft, allowEdit: isDraft, allowDelete: isDraft
    };


    this.fieldMetadata = [
      { name: 'orderType', text: 'Ordretype salg', foreignKeyTableName: 'SalesOrderType', foreignKeyField: 'num' },
      { name: 'orderNumFormat', text: 'Ordrenr. format salg' },
      { name: 'warehouseOutboundOrderType', text: 'Lager udg. ordre', foreignKeyTableName: 'WarehouseOutboundOrderType', foreignKeyField: 'num' },
      { name: 'posted', text: 'Bogført', format: 'CheckBox' }
    ];

  
   
    this.detailViewMetadata = { name: 'salgs-faktura-linjer', dataApiName: 'CustInvoiceLine', text: 'Fakturalinjer', textSingular: 'Fakturalinje',
       primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'invoice', 
       baseFields: ['id', 'history'], titleFields: ['num', 'product', 'name'] };  
    this.detailFieldMetadata = [
      { name: 'line', text: 'Linje',  format: 'Number',  visible: false, orderBy: 'Ascending', orderByPriority: 1 },
      { name: 'product', text: 'ISBN',  required: true,  /*foreignKeyTableName: 'ProductLegacy', foreignKeyField: 'num' */ widthType: FieldWidthEnum.M}, 
      { name: 'name', text: 'Navn', widthType: FieldWidthEnum.M }, 
      { name: 'convertQtyFactor', text: 'Faktor', tooltipText: 'Omregningsfaktor for antal', defaultValue: 1, format: 'Number', required: true, widthType: FieldWidthEnum.XXS },
      { name: 'convertBOM', text: 'Omregning via bogpakke', defaultValue: 1, format: 'CheckBox', required: true, widthType: FieldWidthEnum.XXS  }
    ]
  }
}
