import { Injectable, OnDestroy } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { CerSessionService } from '../cer-session/cer-session.service';
import { CerAppMetaService } from 'src/cer-app/cer-app-meta/cer-app-meta.service';
import { Subscription } from 'rxjs';

export type IdleState = 'not-started' | 'active' | 'ping' | 'idle-start' | 'idle-in' | 'idle-end' | 'timed-out';

@Injectable({
  providedIn: 'root'
})
export class CerIdleService implements OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();

  private idleMinutes: number;
  private keepaliveMinutes: number;

  private idleState: IdleState = 'not-started';

  constructor(/*private idle: Idle, private keepalive: Keepalive,*/ private session: CerSessionService, private appMeta: CerAppMetaService) {
    //this.session.state$.subscribe(state => {
    //  this.idleSetup(state == 'active');
    //});
    //this.subscriptionManager$.add(this.appMeta.sessionIdleMinutes$.subscribe(minutes => { this.idleMinutes = minutes; this.idleSetup(); }));
    //this.subscriptionManager$.add(this.appMeta.sessionKeepaliveMinutes$.subscribe(minutes => { this.keepaliveMinutes = minutes; this.idleSetup();  }));
  }
  
  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }
  
  /*
  private timedout() {
    this.idleStateSet('timed-out');
    this.session.pause();
  }

  private lastPing?: Date = null;
  private keepalivePing() {
    this.idleStateSet("ping");
    this.session.ping();
    this.lastPing = new Date();
  }

  private idleStateSet(state: IdleState, param: string = null) {
    this.idleState = state;
    console.log('IDLE: ' + this.idleState + (param ? ' ' + param : ''));
  }

  private idleSetup(isSessionActive: boolean = null) {
    if (isSessionActive == null) {
      this.idleSetup(this.session.state$.getValue() == 'active');
    }
    if (isSessionActive) {
      if (!this.keepaliveMinutes || !this.idleMinutes) {
        return;
      }
      this.keepalive.interval(this.keepaliveMinutes * 60); // sets the ping interval to 5 minutes
      this.keepalive.onPing.subscribe(() => {
        this.keepalivePing();
      });

      this.idle.setIdle(this.idleMinutes); // sets an idle timeout of x minutes.
      this.idle.setTimeout(1 * 60); // sets a timeout period of 1 minute. After x+1 minutes of inactivity, the client will be paused.
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.onIdleStart.subscribe(() => {
        this.idleStateSet('idle-start');
      });
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleStateSet('idle-in', countdown + 's');
      });

      this.idle.onIdleEnd.subscribe(() => {
        this.idleStateSet('idle-end');
      });

      this.idle.onTimeout.subscribe(() => {
        this.timedout();
      });
      this.idleStart();
    }
    else {
      var notStarted : IdleState = 'not-started';
      if (this.idleState != notStarted) {
        this.idleStateSet(notStarted);
        this.keepalive.stop();
        this.idle.stop();
      }
    }
  }

  private idleStart() {
    this.keepalive.start();
    this.idle.watch();
    this.idleStateSet('active');
  }
*/
}