import { Component, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { FieldGroupMetadata, FieldMetadata, KeyValue, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { CerGridComponent, CerGridSelectionMode } from '../../cer/cer-grid/cer-grid.component';
import { UiCommand } from '../../cer/cer-grid/cer-grid-command.service';

// App
import { CerDataNoteService } from 'src/cer-app/cer-data-note/cer-data-note.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';

import { WarehouseOutboundOrderService } from '../warehousing/warehouse-outbound-order.service';
import { SalesOrderService } from './sales-order.service';

@Component({
  selector: 'sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['../component.css'],
  providers: [SalesOrderService, WarehouseOutboundOrderService]
})

export class SalesOrderComponent {
  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  constructor(
    private salesOrderService: SalesOrderService,
    private outboundOrderService: WarehouseOutboundOrderService,
    private dataNoteService: CerDataNoteService,
    private routeService: CerAppRouteService) {

    var dataNoteTables: string[] = ['WarehouseOutboundOrder', 'SalesOrder'];
    this.dataNoteViewMetadata = dataNoteService.viewMetadataGet(dataNoteTables);
    this.dataNoteFieldMetadata = dataNoteService.fieldMetadataGet(dataNoteTables[0]);
  
    this.manage(this.routeService.routeData$.subscribe(data => this.setupFromRouteData(data)));
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }


  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.manage(this.form.commmand.subscribe(e => this.salesOrderService.onCommand(e)));
    }
  }

  protected showWarehouse: boolean = false;

  protected panesSales: CerFormPanesSettings = {
    top: { visible: 'show', size: '60%' },
    main: { visible: 'show', size: '40%', sizeSecondary: '80%' },
    right: { visible: 'show', size: '20%' },
    orientationPrimary: 'vertical'
  };

  protected panesSalesWarehouse: CerFormPanesSettings = {
    top: { visible: 'show', size: '50%' },
    left: { visible: 'show', size: '30%' },
    main: { visible: 'show', size: '25%', sizeSecondary: '50%' },
    right: { visible: 'show', size: '20%' },
    bottom: { visible: 'show', size: '25%' },
    orientationPrimary: 'vertical'
  };


  salesGrid: CerGridComponent;
  @ViewChild('salesGrid', { static: false }) set salesGridContent(content: CerGridComponent) {
    if (content && !this.salesGrid) {
      this.salesGrid = content;
      this.manage(this.salesGrid.commmand.subscribe(e => this.salesOrderService.onCommand(e)));
    }
  }

  lineGrid: CerGridComponent;
  @ViewChild('lineGrid', { static: false }) set lineGridContent(content: CerGridComponent) {
    if (content && !this.lineGrid) {
      this.lineGrid = content;
    }
  }

  outboundGrid: CerGridComponent;
  @ViewChild('outboundGrid', { static: false }) set outboundGridContent(content: CerGridComponent) {
    if (content && !this.outboundGrid) {
      this.outboundGrid = content;
      this.outboundOrderService.setWarehouseOutboundOrderGrid(this.outboundGrid);
    }
  }

  outboundLineGrid: CerGridComponent;
  @ViewChild('outboundLineGrid', { static: false }) set outboundLineGridContent(content: CerGridComponent) {
    if (content && !this.outboundLineGrid) {
      this.outboundLineGrid = content;
    }
  }

  // Order view
  protected salesViewMetadata: ViewMetadata;
  protected salesFieldMetadata: FieldMetadata[];
  protected salesFieldGroupMetadata: FieldGroupMetadata[];
  protected salesSelectionMode: CerGridSelectionMode = 'row';
  public salesToolbarCommands: UiCommand[];
  public salesContextMenuCommands: UiCommand[];

  // Line 
  protected lineViewMetadata: ViewMetadata;
  protected lineFieldMetadata: FieldMetadata[];

  protected outboundViewMetadata: ViewMetadata;
  protected outboundFieldMetadata: FieldMetadata[];
  protected outboundFieldGroupMetadata: FieldGroupMetadata[];
  protected outboundToolbarCommands: UiCommand[];
  protected outboundContextMenuCommands: UiCommand[];
  protected outboundLineViewMetadata: ViewMetadata;
  protected outboundLineFieldMetadata: FieldMetadata[];
  protected dataNoteViewMetadata: ViewMetadata;
  protected dataNoteFieldMetadata: FieldMetadata[];

  private params: any;
  private isNew: boolean = false;
  private isPlanning: boolean = false;
  private isRemain: boolean = false;
  private isDelivered: boolean = false;
  private isCaptureReady: boolean = false;
  private isCaptured: boolean = false;
  private isInvoiceCreditReady: boolean = false;
  private isInvoiced: boolean = false;
  private isCompleted: boolean = false;
  private isCancelled: boolean = false;

  private setupFromRouteData(routeData: any) {
    if (routeData?.params) {
      var params = routeData.params;
      this.params = params;
      this.isNew = params.includes("$statusGroup=New");
      this.isPlanning = params.includes("$statusGroup=Planning");
      this.isRemain = params.includes("$statusGroup=Remain");
      this.isDelivered = params.includes("$statusGroup=Delivered");
      this.isCaptureReady = params.includes("$statusGroup=CaptureReady");
      this.isInvoiceCreditReady = params.includes("$statusGroup=InvoiceCreditReady");
      this.isCaptured = params.includes("$statusGroup=Captured");
      this.isInvoiced = params.includes("$statusGroup=Invoiced");
      this.isCompleted = params.includes("$statusGroup=Completed");
      this.isCancelled = params.includes("$statusGroup=Cancelled");
    }
    this.showWarehouse = !this.isNew;
    this.initSales();
    this.initOutbound();

    var dataNoteTables: string[] = ['SalesOrder', 'WarehouseOutboundOrder'];
    this.dataNoteViewMetadata = this.dataNoteService.viewMetadataGet(dataNoteTables);
    this.dataNoteFieldMetadata = this.dataNoteService.fieldMetadataGet(dataNoteTables[0]);
  }

  private initSales() {
    var svc: SalesOrderService = this.salesOrderService;
    this.salesViewMetadata = svc.salesOrderViewMetadata;
    this.salesViewMetadata.dataApiParams = this.params;
    this.salesFieldMetadata = [...svc.salesOrderFieldMetadata];
    if (this.isCaptureReady || this.isCaptured) {
      this.salesFieldMetadata.filter(f => { f.name == 'custVATNum' || f.name == 'custEAN' }).forEach(f => f.visible = false);
      this.salesSelectionMode = 'checkboxMulti';
    }
    if (this.isInvoiceCreditReady) {
      this.salesFieldMetadata.filter(f => f.name == 'paymentReference').forEach(f => f.visible = false);
      this.salesSelectionMode = 'checkboxMulti';
    }
    this.salesFieldGroupMetadata = svc.salesOrderFieldGroupMetadata;
    this.salesToolbarCommands = svc.orderToolbarCommandsGet(this.isCaptureReady, this.isInvoiceCreditReady);
    this.salesContextMenuCommands =  svc.orderContextMenuCommandsGet(this.isCaptureReady, this.isInvoiceCreditReady);
    this.lineViewMetadata = svc.salesLineViewMetadata;
    this.lineFieldMetadata = svc.salesLineFieldMetadata;
  }

  private initOutbound() {
    if (this.showWarehouse) {
      var svc: WarehouseOutboundOrderService = this.outboundOrderService;
      this.outboundViewMetadata = svc.orderViewMetadata;
      this.outboundFieldMetadata = svc.orderFieldMetadata;
      this.outboundFieldGroupMetadata = svc.orderFieldGroupMetadata;
      this.outboundToolbarCommands = svc.orderToolbarCommands;
      this.outboundContextMenuCommands = svc.orderContextMenuCommands;
      this.outboundLineViewMetadata = svc.orderLineViewMetadata;
      this.outboundLineFieldMetadata = svc.orderLineFieldMetadata;
    }
  }

}
