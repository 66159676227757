// Angular
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// Grid
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppFileViewerTabComponent } from 'src/cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';

// WebApi
import {
  ChatDto,
  LinkedAttachmentDto,
  VoucherApprovalStatusAllEnum,
  VoucherMessageViewDetailsVm,
} from 'src/app/api';

// App
import { VoucherDataService } from '../voucher-data.service';
import { VoucherViewService } from './voucher-view.service';
import { VoucherPostingViewService } from './voucherposting-view.service';
import { VoucherAttachmentService } from '../voucher-attachment.service';
import { CerFormPaneVisible, CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { UiActionTypeEnum, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldMetadata, ViewMetadata } from 'src/cer/cer-data/cer-data.service';

@Component({
  selector: 'voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['../voucher.css'],
  providers: [VoucherDataService, VoucherViewService, VoucherPostingViewService, VoucherAttachmentService]
})

export class VoucherListComponent implements OnInit, OnDestroy {
  // State
  protected setupComplete: boolean = false;
  protected tabVisible: CerFormPaneVisible = 'show';
  protected postingVisible: CerFormPaneVisible = 'show';
  protected custInvoiceVisible: CerFormPaneVisible = 'hide';

  // File Viewer Tab State
  protected attachments: LinkedAttachmentDto[];
  protected chats: ChatDto[];
  protected attachmentToken: string;
  protected attachmentRefRowId: number;
  protected attachmentSaveUrl: string;
  protected attachmentRemoveUrl: string;

// Invoice view
protected invoiceViewMetadata: ViewMetadata = {
  name: 'cust-invoice', dataApiName: 'CustInvoice', text: 'Fakturaer',
  primaryKey: 'id', parentPrimaryKey: 'msgId', parentForeignKey: 'voucherMessage', titleFields: ['num'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['id','num','history']
};

// Invoice fields
protected invoiceFieldMetadata: FieldMetadata[] =
  [{ name: 'num', text: 'Nummer', tooltipText: 'Fakturaens interne nr.' },
   { name: 'status', text: 'Bogført', format: 'CheckBox' },
   { name: 'amountCur', text: 'Beløb', format: 'Amount', footerType: 'Sum' },
   { name: 'paymentFeeMst', text: 'Gebyrbeløb', format: 'Amount', footerType: 'Sum'},
   { name: 'invoiceDate', text: 'Fakturadato', format: 'DateShort'},
   { name: 'settleDate', text: 'Betalingsdato', format: 'DateShort'},
   { name: 'paymentReference', text: 'Betalingsref.'}
];


  constructor(
    private voucherDataService: VoucherDataService,
    private voucherViewService: VoucherViewService,
    private voucherPostingViewService: VoucherPostingViewService,
    protected attachmentService: VoucherAttachmentService) {
  }

  protected panes: CerFormPanesSettings = {
    top: { size: '60%' },
    main: { visible: this.postingVisible, size: '60%',  sizeSecondary: '20%' },
    bottom: { visible: this.custInvoiceVisible, size: '20%' },
    right: { visible: this.tabVisible, size: '40%' },
    orientationPrimary: 'horizontal'
  };

  public ngOnInit(): void {
    this.voucherDataService.init();
    this.voucherViewService.init();
    this.voucherPostingViewService.init();
    this.manage(this.voucherDataService.setupComplete$.subscribe(ok => this.setup()));
    this.manage(this.voucherDataService.detailsVm$.subscribe(vm => this.onDetailVm(vm)));
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  public ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  // Form
  private form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && this.form != content) {
      this.form = content;
      this.voucherViewService.initForm(this.form);
      this.initFileViewerTab();
      this.panesInit();
    }
  }

  private panesInit() {
    if (this.form && this.setupComplete) {
      this.panes.right.visible = this.tabVisible;
      this.panes.main.visible = this.postingVisible;
      this.panes.bottom.visible = this.custInvoiceVisible;
      this.form.panesService.panesInit(this.panes);
    }
  }

  // FileViewerTab
  private tabAttachments: CerAppFileViewerTabComponent;
  @ViewChild('tabAttachments', { static: false }) set tabContent(content: CerAppFileViewerTabComponent) {
    if (content && this.tabAttachments != content) {
      this.tabAttachments = content;
      this.initFileViewerTab();
    }
  }

  private initFileViewerTab() {
    if (this.tabAttachments) {
      if (this.form) {
        this.attachmentService.init(this.form, this.tabAttachments);
      }
      this.tabAttachments.privateNoteActive = this.voucherDataService.userIsAdmin;
    }
    this.voucherViewService.fileViewerTab = this.tabAttachments;
  }

  // Grid
  private grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && this.grid != content) {
      this.grid = content;
      this.voucherViewService.initGrid(this.grid);
      this.voucherDataService.initGrid(this.grid);
      this.manage(this.grid.commmand.subscribe(cmd => this.onGridCommand(cmd)));
    }
  }

  protected onGridCommand(cmd: UiCommandEvent) {
    if (cmd.actionType == UiActionTypeEnum.ChatClicked) {
      this.tabAttachments.tabSelectIndex(this.attachments?.length ?? 0);
      this.voucherViewService.chatOpen();
    }
  }

  // Posting grid
  private gridPosting: CerGridComponent;
  @ViewChild('gridPosting', { static: false }) set gridPostingContent(content: CerGridComponent) {
    if (content && !this.gridPosting) {
      this.gridPosting = content;
      this.voucherPostingViewService.initGrid(this.gridPosting);
    }
  }

  // CustInvoice grid
  private gridCustInvoice: CerGridComponent;
  @ViewChild('gridCustInvoice', { static: false }) set gridCustInvoiceContent(content: CerGridComponent) {
    if (content && !this.gridCustInvoice) {
      this.gridCustInvoice = content;
      //this.voucherDataService.initCustInvoiceGrid(this.gridCustInvoice);
    }
  }

  private setup(): void {
    var disablePosting = (this.voucherDataService.isManualVoucher || this.voucherDataService.filterApprovalStatus == VoucherApprovalStatusAllEnum.Created);
    this.postingVisible = disablePosting ? "hide" : "show";
    this.custInvoiceVisible = this.voucherDataService.filterCustInvoice === true ? "show" : "hide";
    this.setupComplete = true;
    this.panesInit();
  }

  // File viewer
  private onDetailVm(vm: VoucherMessageViewDetailsVm) {
    this.chats = vm?.chatDtoList ?? [];
    this.attachmentToken = vm?.voucherMessageViewDto?.messageToken;
    this.attachmentRefRowId = vm?.voucherMessageViewDto?.id;
    this.attachments = vm?.voucherMessageAttachmentDtoList ?? [];
    var idx = this.attachmentService.getAttachmentSelectextIdxDefault(this.attachments)
    this.tabAttachments?.tabSelectIndex(idx);
    this.attachmentSaveUrl = vm?.attachmentsSaveUrl;
    this.attachmentRemoveUrl = vm?.attachmentsRemoveUrl;
    if (this.grid && vm?.voucherViewDtoList) {
      (<any>vm.voucherViewDtoList).chat = this.chats;
      this.grid.editCellService.chatColumnRefresh(vm.voucherViewDtoList);
    }
  }

  protected onAttachmentTabSelectedIndexChanged(index: number) {
    this.voucherViewService.voucherDetailsDialogAttachmentIdxDefault = index;
  }

  protected onAttachmentCheckBoxApply(checkedFileNames: string[]) {
    var id: number = this.tabAttachments.refRowId;
    if (id) {
      var callback = () => {
        this.voucherDataService.detailsVmMap.delete(id); // force reload
        this.voucherViewService.research();
      }
      this.attachmentService.messageSplitByAttachmentHere(id, checkedFileNames, callback);
    }
  }
}

