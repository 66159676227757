import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs';

import { ChatDto, LinkedAttachmentDto, RightsApprovalStatusEnum, RightsCloseStatusEnum } from '../../api';
import { AppStateService } from '../../../app-core/app-state/app-state.service';
import { AppUser, UserSessionService } from '../../../platform/app-user/user-session-service';
import { CerAppChatService } from '../../../cer-app/cer-app-chat/cer-app-chat.service';
import { ActivatedRoute } from '@angular/router';
import { FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { uiShortcutPaneRightEnlarge, uiShortcutPaneRightReduce } from '../../../cer/cer-form/cer-form-panes.service';
import { CerGridComponent } from '../../../cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommandSourceEnum, UiCommand, uiCmdSeparator, UiKeyboardShortcut, UiCommandEvent } from '../../../cer/cer-grid/cer-grid-command.service';
import { fileViewerCmdZoomIn, fileViewerCmdZoomOut, fileViewerShortcutZoomIn, fileViewerShortcutZoomOut, CerAppFileViewerTabComponent } from '../../../cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';

import { RightsApprovalDto as Dto, RightsPeriodDto, RightsApprovalClient as Client, RoleTypeEnum } from '../../api';
import { RightsFormatterApprovalStatus } from './rights-formatter-approval-status';
import { RightsFormatterCloseStatus } from './rights-formatter-close-status';
import { CerGridSelectionService } from 'src/cer/cer-grid/cer-grid-selection.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';

const uiCmdApprovalApprove: UiCommand = { id: 'ApprovalApprove', text: 'Godkend', iconCss: "e-menu-icon e-icons-org e-changes-accept", tooltipText: 'Godkend markeret post', target: ".e-content", needSelection: true };
const uiCmdApprovalApproveAndClose: UiCommand = { id: 'ApprovalApproveAndClode', text: 'Godkend og luk', iconCss: "e-menu-icon e-icons-org e-table-update", tooltipText: 'Godkend og luk markeret post', target: ".e-content", needSelection: true };
const uiCmdApprovalReject: UiCommand = { id: 'ApprovalReject', text: 'Afvis', iconCss: "e-menu-icon e-icons-org e-changes-reject", tooltipText: 'Afvis markeret post', target: ".e-content", needSelection: true };
const uiCmdCloseModifiedOngoing: UiCommand = { id: 'CloseModifiedOngoing', text: 'Rettelse igang', iconCss: "e-menu-icon e-icons-org e-display-for-review", tooltipText: 'Rettelse er igangsat', target: ".e-content", needSelection: true };
const uiCmdCloseModifiedForApproval: UiCommand = { id: 'CloseModifiedForApproval', text: 'Godkend rettelse', iconCss: "e-menu-icon e-icons-org e-changes-previous", tooltipText: 'Rettelse gennemført', target: ".e-content", needSelection: true };
const uiCmdCloseCompleted: UiCommand = { id: 'CloseCompleted', text: 'Afsendt', iconCss: "e-menu-icon e-icons-org e-changes-next", tooltipText: 'Afsendelse gennemført', target: ".e-content", needSelection: true };

@Component({
  selector: 'app-rights-approval',
  templateUrl: './rights-approval.component.html',
  styleUrls: ['./rights-approval.component.css']
})

export class RightsApprovalComponent implements OnDestroy, OnInit {
  // Get form
  private form: CerFormComponent;
  @ViewChild(CerFormComponent) set setForm(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.manage(this.form.formService.formCommand$.subscribe(e => this.onCommand(e)));
    }
  }

  mainGrid: CerGridComponent = null;
  @ViewChild('mainGrid', { static: false }) set mainGridContent(content: CerGridComponent) {
    if (content && !this.mainGrid) {
      this.mainGrid = content;
      this.mainGrid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  fileViewerTab: CerAppFileViewerTabComponent;
  @ViewChild('fileViewerTab', { static: false }) set fileViewerTabContent(content: CerAppFileViewerTabComponent) {
    if (content && !this.fileViewerTab) {
      this.fileViewerTab = content;
    }
  }

  public rightsPeriods: BehaviorSubject<RightsPeriodDto[]> = new BehaviorSubject<RightsPeriodDto[]>(null);
  public dto: Dto = null;
  public chats: ChatDto[] = null;
  public attachmentDtoList: LinkedAttachmentDto[] = null;

  public viewMetadata: ViewMetadata = {
    name: 'rettigheder-godkendelse', text: 'Godkendelser', textSingular: 'Godkendelse',
    primaryKey: 'id',
    allowEdit: true, allowCreate: false, allowDelete: false,
    titleFields: ['vendorNum', 'description'],
    dataApiName: 'RightsApproval', baseFields: ['history'],
    dataApiParams: ['$periodActiveFilter', '$includeDataLinks']
  };

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'chat', text: 'Chat', tooltipText: 'Se chatbeskeder', allowEdit: false, format: 'Chat', visibleAdd: false, visibleEdit: false },
    { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false },
    { name: 'approver', text: 'Godkender', tooltipText: 'Redaktør der skal godkende', widthType: FieldWidthEnum.S, foreignKeyTableName: 'User', foreignKeyField: 'id', foreignKeyValue: 'shortName' },
    { name: 'approvalStatus', text: 'Status', tooltipText: 'Godkendelsesstatus', widthType: FieldWidthEnum.M, foreignKeyTableName: 'RightsApprovalStatus', formatter: new RightsFormatterApprovalStatus() },
    { name: 'closeStatus', text: 'Luk status', tooltipText: 'Lukkestatus', widthType: FieldWidthEnum.M, foreignKeyTableName: 'RightsCloseStatus', formatter: new RightsFormatterCloseStatus() },
    { name: 'vendorNum', text: 'Nr.', tooltipText: 'Forfatter nr.', widthType: FieldWidthEnum.S, allowEdit: false, orderBy: 'Descending' },
    { name: 'description', text: 'Beskrivelse', tooltipText: 'Beskrivelse af godkendelse', allowEdit: false, widthType: FieldWidthEnum.M },
    { name: 'period', text: 'Periode', tooltipText: 'Periode', widthType: FieldWidthEnum.S, visible: true, allowEdit: false, allowEditOnCreate: true, /*foreignKeySubject: this.rightsPeriods,*/ foreignKeyTableName: 'RightsPeriod' },
    { name: 'folder', text: 'Folder', tooltipText: 'Folder for sti', widthType: FieldWidthEnum.S, allowEdit: false, visible: false },
    { name: 'fileName', text: 'Filnavn', tooltipText: 'Filnavn til godkendelse', widthType: FieldWidthEnum.M, allowEdit: false, visible: false }
    ];

  public toolbarCommands: UiCommand[] = [uiCmdApprovalApprove, uiCmdApprovalApproveAndClose, uiCmdApprovalReject, uiCmdSeparator];
  public contextMenuCommands: UiCommand[] = [uiCmdApprovalApprove, uiCmdApprovalApproveAndClose, uiCmdApprovalReject];
  public adminCommands: UiCommand[] = [uiCmdCloseModifiedOngoing, uiCmdCloseModifiedForApproval, uiCmdCloseCompleted];
  public keyboardShortcuts: UiKeyboardShortcut[] = [uiShortcutPaneRightReduce, uiShortcutPaneRightEnlarge, fileViewerShortcutZoomIn, fileViewerShortcutZoomOut];

  public myApprovalsFilter: boolean;

  public subscriptionManager: Subscription = new Subscription()

  constructor(route: ActivatedRoute, private client: Client, private userSessionService: UserSessionService, private appStateService: AppStateService,
    private chatService: CerAppChatService, private appUserService: UserSessionService) {
    //this.manage(this.rightsPeriods.subscribe(data => this.setPeriodFilterActive(data)));
    this.manage(this.chatService.chats$.subscribe(chats => this.chatsRefresh(chats)));

    this.setInitialFilter(route);
  }

  manage(subscription: Subscription) {
    this.subscriptionManager.add(subscription);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  public setInitialFilter(route: ActivatedRoute) {

    this.myApprovalsFilter = (route.snapshot.data['myApprovals'] == true);

    if (this.myApprovalsFilter) {
      this.fieldMetadata.filter(f => (f.name == 'approver')).forEach(f => {
        f.filterOperator = "equal";
        f.filterValue = this.userSessionService.user$?.getValue()?.dto?.id;
        if (f.filterValue) {
          f.visible = false;
          f.visibleEdit = true;
        }
      });
    }
    var user: AppUser = this.userSessionService.user$.getValue();
    var isAdmin: boolean = (user && this.appUserService.userRolesCheck([RoleTypeEnum.RightsAdmin], user.roles));
    if (isAdmin) {
      if (!this.myApprovalsFilter) {
        this.toolbarCommands = this.adminCommands;
      }
      this.contextMenuCommands = this.contextMenuCommands.concat([uiCmdSeparator]).concat(this.adminCommands);
    }
    else {
      this.fieldMetadata.filter(f => (f.name == 'closeStatus')).forEach(f => {
        f.allowEdit = false;
      });
    }
  }

  public setApprovalStatus(approvalStatus: RightsApprovalStatusEnum) {
    var dto: Dto = <Dto>this.mainGrid.rowSelectedData();
    if (dto && dto.id) {
      this.client.setApprovalStatus(dto.id, approvalStatus).subscribe(
        data => this.mainGrid.gridService.dataRowReplace(dto, data, +1),
        error => this.appStateService.handleApiError(error)
      );
    }
  }

  public setCloseStatus(closeStatus: RightsCloseStatusEnum) {
    var dto: Dto = <Dto>this.mainGrid.rowSelectedData();
    if (dto && dto.id) {
      this.client.setCloseStatus(dto.id, closeStatus).subscribe(
        data => this.mainGrid.gridService.dataRowReplace(dto, data, +1),
        error => this.appStateService.handleApiError(error)
      );
    }
  }

  public chatsRefresh(chats: ChatDto[], id: number = null) {
    if (this.dto && this.dto.id && this.mainGrid) {
      if (chats?.length > 0) {
        var chat: ChatDto = chats[0];
        if (chat.refTableId == this.fileViewerTab.refTableId && chat.refRowId == this.dto.id) {
          this.dto.chat = chats;
          this.chats = chats;
          this.fileViewerTab.refreshChat();
          this.mainGrid.editCellService.chatColumnRefresh(this.dto);
        }
      }
    }
  }

  public rowSelect(event: any): any {
    var dto: Dto = <Dto>this.mainGrid.rowSelectedData();
    if (dto) {
      this.attachmentDtoList = dto.linkedAttachment;
      if (!CerGridSelectionService.rowDataIsEqual(dto.chat, this.chats)) {
        this.chats = dto.chat;
        this.fileViewerTab.refreshChat();
      }
      if (!CerGridSelectionService.rowDataIsEqual(dto, this.dto)) {
        this.dto = dto;
        this.fileViewerTab.refreshChat();
      }
    }
  }

  public fileViewerResize() {
    if (this.fileViewerTab) {
      this.fileViewerTab.resize();
    }
  }

  public fileViewerZoomIn() {
    if (this.fileViewerTab) {
      this.fileViewerTab.pdfZoomIn();
    }
  }

  public fileViewerZoomOut() {
    if (this.fileViewerTab) {
      this.fileViewerTab.pdfZoomOut();
    }
  }

  // Commands
  disableEdit: boolean = false;
  public onCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.DesignChange:
        this.fileViewerResize();
        break;
      case UiCommandSourceEnum.ActionBegin:
        var calledFromChatColumn: boolean = event.columnName == 'chat';
        switch (event.actionType) {
          case UiActionTypeEnum.RowClick:
            if (calledFromChatColumn) {
              this.chatShow();
            }
            break;
          case UiActionTypeEnum.RowDoubleClick:
            this.disableEdit = calledFromChatColumn;
            break;
          case UiActionTypeEnum.BeginEdit:
            if (this.disableEdit) {
              event.cancel = true;
              this.disableEdit = false;
            }
            break;
        }
        break;

      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.RowSelect:
            this.rowSelect(event);
            break;
        }
        break;
      case UiCommandSourceEnum.Command:
        if (event.actionType == UiActionTypeEnum.ChatClicked) {
          this.chatShow();
        }
        break;
      default:
        switch (event.commandId) {
          case fileViewerCmdZoomIn.id:
            this.fileViewerZoomIn();
            break;
          case fileViewerCmdZoomOut.id:
            this.fileViewerZoomOut();
            break;
          case uiCmdApprovalApprove.id:
            this.setApprovalStatus(RightsApprovalStatusEnum.Approved);
            break;
          case uiCmdApprovalApproveAndClose.id:
            this.setApprovalStatus(RightsApprovalStatusEnum.ApprovedAndCloseContract);
            break;
          case uiCmdApprovalReject.id:
            this.setApprovalStatus(RightsApprovalStatusEnum.Rejected);
            break;
          case uiCmdCloseModifiedOngoing.id:
            this.setCloseStatus(RightsCloseStatusEnum.ModifiedOngoing);
            break;
          case uiCmdCloseModifiedForApproval.id:
            this.setCloseStatus(RightsCloseStatusEnum.ModifiedForApproval);
            break;
          case uiCmdCloseCompleted.id:
            this.setCloseStatus(RightsCloseStatusEnum.Completed);
            break;
        }
    }
  }

  private chatShow() {
    this.form.panesService.show('right'); // Always show right pane (is always open!!)
    var idx: number = this.attachmentDtoList.length ?? 0;
    this.fileViewerTab.tabSelectIndex(idx);
    this.fileViewerTab.chatOpen(idx);
  }
}
