import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

import { CerAppDialogService } from "src/cer-app/cer-app-dialog/cer-app-dialog.service";
import { CerDialogService } from "src/cer/cer-dialog/cer-dialog.service";
import { CerAppRouteService } from "src/cer-app/cer-app-route/cer-app-route.service";
import { FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from "src/cer/cer-data/cer-data.service";
import { uiCmdSeparator, UiCommand, UiCommandEvent } from "src/cer/cer-grid/cer-grid-command.service";

import { SalesOrderClient, SalesOrdersPaymentCommand } from "../api";

import { uiCmdBottomToggle } from "src/cer/cer-form/cer-form-panes.service";
import { CerGridComponent } from "src/cer/cer-grid/cer-grid.component";

// Commands
export const uiCmdOrderSyncSelectedRow: UiCommand = { id: 'uiCmdOrderSyncSelectedRow', text: 'Opdater markeret ordre', tooltipText: 'Opdater status på markeret salgsordre og forsendelser', iconCss: 'e-menu-icon e-icons-org e-repeat', target: ".e-content", needSelection: true };
export const uiCmdOrderSyncAll: UiCommand = { id: 'uiCmdOrderSyncAll', text: 'Opdater', tooltipText: 'Opdater ordrer fra salgskanaler og forsendelser', iconCss: 'e-menu-icon e-icons-org e-repeat' };
export const uiCmdOrderSyncAllContext: UiCommand = Object.assign({ text: 'Opdater alle', uiCmdOrderSyncAll });
export const uiCmdSyncSalesOrderSelected: UiCommand = { id: 'uiCmdSyncSalesOrderSelected', text: 'Opdater denne ordre', tooltipText: 'Opdater data om denne ordre', iconCss: 'e-menu-icon e-icons-org e-repeat', target: ".e-content", needSelection: true };
export const baseUrlTrackTrace = 'www.postnord.dk/varktojer/track-trace?shipmentId=';
export const uiCmdSalesOrderCaptureRow: UiCommand = { id: 'uiCmdSalesOrderCaptureRow', text: 'Indløs ordre', tooltipText: 'Indløs/capture betalinge på aktuel salgsorde', iconCss: 'e-menu-icon e-icons-org e-thumbs-up', target: ".e-content", needSelection: true };
export const uiCmdSalesOrderCaptureRows: UiCommand = { id: 'uiCmdSalesOrderCaptureRows', text: 'Indløs ordrer', tooltipText: 'Indløs/capture betalinger på markerede salgsorder', iconCss: 'e-menu-icon e-icons-org e-thumbs-up', target: ".e-content", needSelection: true };
export const uiCmdSalesOrderInvoiceReleaseRow: UiCommand = { id: 'uiCmdSalesOrderInvoiceReleaseRow', text: 'Send ordre til fakturakø', tooltipText: 'Meld aktuel salgsordre klar til fakturering', iconCss: 'e-menu-icon e-icons-org e-changes-accept', target: ".e-content", needSelection: true };
export const uiCmdSalesOrderInvoiceReleaseRows: UiCommand = { id: 'uiCmdSalesOrderInvoiceReleaseRows', text: 'Send ordrer til fakturakø', tooltipText: 'Meld markerede salgsordrer klar til fakturering', iconCss: 'e-menu-icon e-icons-org e-changes-accept', target: ".e-content", needSelection: true };

export const uiCmdWarehouseOutboundOrderRelease: UiCommand = { id: 'warehouseOutboundOrderRelease', text: 'Frigiv forsendelse', tooltipText: 'Frigiv forsendelse til lager', iconCss: 'e-menu-icon e-icons-org e-changes-accept', target: ".e-content", needSelection: true };
export const uiCmdWarehouseOutboundOrderCancel: UiCommand = { id: 'warehouseOutboundOrderCancel', text: 'Annuller forsendelse', tooltipText: 'Annuller forsendelse', iconCss: 'e-menu-icon e-icons-org e-changes-reject', target: ".e-content", needSelection: true };
export const uiCmdWarehouseOutboundOrderComplete: UiCommand = { id: 'warehouseOutboundOrderComplete', text: 'Afslut forsendelse', tooltipText: 'Afslut forsendelse', iconCss: 'e-menu-icon e-icons-org e-table-update', target: ".e-content", needSelection: true };
export const uiCmdWarehouseOutboundOrderReorderCancelledLines: UiCommand = { id: 'warehouseOutboundOrderReorderCancelledLines', text: 'Genbestil annullerede', tooltipText: 'Genbestil annullerede linjer', iconCss: 'e-menu-icon e-icons-org e-table-update', target: ".e-content", needSelection: true };
export const uiCmdOrderSync: UiCommand = { id: 'uiCmdOrderSync', text: 'Hent status salg og forsendelser', tooltipText: 'Hent data omkring salg og forsendelser', iconCss: 'e-menu-icon e-icons-org e-repeat', target: ".e-content" };

@Injectable({ providedIn: 'root' })
export class SalesOrderService {

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  constructor(
    private client: SalesOrderClient,
    private routeService: CerAppRouteService,
    private dialog: CerDialogService,
    private ui: CerAppDialogService) {
  }

  // View sales order
  public salesOrderViewMetadata: ViewMetadata = {
    name: 'sales-order', dataApiName: 'SalesOrder', text: 'Salgsordrer',
    primaryKey: 'num', titleFields: ['name'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['id', 'num', 'name', 'history']
  };
  public salesOrderFieldMetadata: FieldMetadata[] =
    [
      { name: 'num', text: 'Ordrenr.', tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.S, orderBy: 'Descending', orderByPriority: 3 },
      { name: 'status', text: 'Status', tooltipText: 'Ordrestatus', foreignKeyTableName: 'SalesOrderStatus', foreignKeyField: 'id', foreignKeyValue: 'name', foreignKeySortFields: ['sorting'], orderBy: 'Ascending', orderByPriority: 1 },
      { name: 'orderDate', text: 'Ordredato', format: 'DateShort', orderBy: 'Descending', orderByPriority: 2 },
      { name: 'orderChangeDate', text: 'Ordre ændring', format: 'DateShort', visible: false },
      { name: 'channel', text: 'Salgskanal', tooltipText: 'Kanal', widthType: FieldWidthEnum.XS, foreignKeyTableName: 'SalesChannel', foreignKeyField: 'num' },
      { name: 'channelOrder', text: 'Ordre (kanal)', tooltipText: 'Kanalordre', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'reservedDate', text: 'Reserveret', format: 'DateShort', visible: false },
      { name: 'shipDate', text: 'Leveret', format: 'DateShort', visible: false },
      { name: 'invoiceDate', text: 'Faktureret', format: 'DateShort', visible: false },
      { name: 'invoiceNum', text: 'Faktura', format: 'DateShort', visible: false },
      { name: 'captureDate', text: 'Indløst', format: 'DateShort', visible: false },
      { name: 'settleDate', text: 'Udlignet', format: 'DateShort', visible: false },
      { name: 'name', text: 'Navn', tooltipText: 'Navn', required: true, allowEdit: true, widthType: FieldWidthEnum.M },
      { name: 'billFirstName', text: 'Fak. fornavn', tooltipText: 'Faktura fornavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'billLastName', text: 'Fak. efternavn', tooltipText: 'Faktura efternavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'billCompanyName', text: 'Fak. firmanavn', tooltipText: 'Faktura firmanavn', widthType: FieldWidthEnum.XL },
      { name: 'custNote', text: 'Notat kunde', tooltipText: 'Kundens notat', format: 'TextArea', widthType: FieldWidthEnum.L },
      { name: 'custEmail', text: 'E-mail', tooltipText: 'Kunde e-mail', format: 'Email' },
      { name: 'custPhone', text: 'Telefon', tooltipText: 'Kunde telefon', format: 'Phone' },
      { name: 'custNum', text: 'Kundenr.', tooltipText: 'Kundenr.', widthType: FieldWidthEnum.M },
      { name: 'custEAN', text: 'EAN nr.', widthType: FieldWidthEnum.M },
      { name: 'custVATNum', text: 'CVR nr.', tooltipText: 'Kundens CVR nummer', widthType: FieldWidthEnum.M },
      { name: 'custVATExempt', text: 'Momsfri', tooltipText: 'Er ordren momsfri?', format: 'CheckBox', visible: false },
      { name: 'paymentType', text: 'Betalingstype', tooltipText: 'Afregningstype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesPaymentType', foreignKeyField: 'num' },
      { name: 'paymentReference', text: 'Indløser ref.', tooltipText: 'Betalingsindløser ref.', allowEdit: true, widthType: FieldWidthEnum.S },
      { name: 'type', text: 'Type', tooltipText: 'Ordretype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesOrderType', foreignKeyField: 'num' },
      { name: 'currency', text: 'Valuta', widthType: FieldWidthEnum.XXXS },
      { name: 'amountCurExTax', text: 'Fakturabeløb u. moms', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'amountCurTax', text: 'Momsbeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M, visible: false },
      { name: 'amountCur', text: 'Fakturabeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'billAddress1', text: 'Fak. adresse 1', tooltipText: 'Faktura adresse 1', widthType: FieldWidthEnum.L },
      { name: 'billAddress2', text: 'Fak. adresse 2', tooltipText: 'Faktura adresse 2', widthType: FieldWidthEnum.L },
      { name: 'billPostalCode', text: 'Fak. postnr.', tooltipText: 'Faktura postnr.', widthType: FieldWidthEnum.S },
      { name: 'billPostalDistrict', text: 'Fak. by', tooltipText: 'Faktura by', widthType: FieldWidthEnum.L },
      { name: 'billCountryCode', text: 'Fak. land', tooltipText: 'Faktura landekode', widthType: FieldWidthEnum.S },
      { name: 'carrier', text: 'Transportør', foreignKeyTableName: 'WarehouseCarrier', foreignKeyField: 'num' },
      { name: 'carrierServicePoint', text: 'Pakkeshop', foreignKeyTableName: 'WarehouseCarrierServicePoint', foreignKeyField: 'keyCode', foreignKeyColumns: ['keyCountryCode', 'keyType', 'key'], visible: false, visibleEdit: true },
      { name: 'shipToInvoiceAddress', text: 'Lev. fak.adresse', tooltipText: 'Levering til fakturaadresse', format: 'CheckBox' },
      { name: 'shipFirstName', text: 'Lev. fornavn', tooltipText: 'Levering fornavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipLastName', text: 'Lev. efternavn', tooltipText: 'Levering efternavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipCompanyName', text: 'Lev. firmanavn', tooltipText: 'Leveringfirmanavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipAddress1', text: 'Lev. adresse 1', tooltipText: 'Levering adresse 1', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipAddress2', text: 'Lev. adresse 2', tooltipText: 'Levering adresse 2', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipPostalCode', text: 'Lev. postnr.', tooltipText: 'Levering postnr.', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'shipPostalDistrict', text: 'Lev. by', tooltipText: 'Levering by', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipCountryCode', text: 'Lev. land', tooltipText: 'Levering landekode', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'sourceUTM', text: 'Kampagne', visible: false },
      { name: 'sourceDevice', text: 'Device', visible: false },
      { name: 'createdJobId', text: 'Job oprettet', format: 'Integer', visible: false },
      { name: 'modifiedJobId', text: 'Job rettet', format: 'Integer', visible: false }
    ];
  public salesOrderFieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'num', 'channel', 'channelOrder', 'type', 'status', 'orderDate'], width: 240 },
    { idx: 2, tabIdx: 0, name: 'customer', text: 'Kunde', fields: ['name', 'custEmail', 'custPhone', 'custNum', 'custEAN', 'custVATNum', 'custVATExempt', 'custType'] },
    { idx: 3, tabIdx: 0, name: 'amounts', text: 'Beløb', fields: ['amountCurExTax', 'amountCurTax', 'amountCur', 'currency'] },
    { idx: 3, tabIdx: 0, name: 'settlement', text: 'Afregning', fields: ['settlementType', 'paymentType', 'paymentMethod', 'paymentTransaction', 'paymentReference', 'closed', 'paymentCaptured'] },
    { idx: 4, tabIdx: 0, name: 'carrier', text: 'Transport', fields: ['carrier', 'carrierServicePoint', 'custNote'] },
    { idx: 5, tabIdx: 0, name: 'shipment', text: 'Levering', fields: ['shipFirstName', 'shipLastName', 'shipCompanyName', 'shipAddress1', 'shipAddress2', 'shipPostalCode', 'shipPostalDistrict', 'shipCountryCode', 'shipToInvoiceAddress'] },
    { idx: 6, tabIdx: 0, name: 'bill', text: 'Faktura', fields: ['billFirstName', 'billLastName', 'billCompanyName', 'billAddress1', 'billAddress2', 'billPostalCode', 'billPostalDistrict', 'billCountryCode'] },
    { idx: 7, tabIdx: 0, name: 'other', text: 'Øvrige', fields: [] }
  ];
  private uiCmdBottomToggleLines = Object.assign(new Object(), uiCmdBottomToggle, { text: 'Linjer', tooltipText: 'Vis linjer' });
  private orderToolbarCommands: UiCommand[] = [uiCmdOrderSyncAll];
  private orderContextMenuCommands: UiCommand[] = [uiCmdOrderSyncSelectedRow, uiCmdOrderSyncAllContext];

  // Detail view
  public salesLineViewMetadata: ViewMetadata = {
    name: 'sales-order-line', dataApiName: 'SalesOrderLine', text: 'Ordre linjer',
    primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'order', titleFields: ['order', 'type', 'product'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };

  // Detail  fields
  public salesLineFieldMetadata: FieldMetadata[] = [
    { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false, isIdentity: true, widthType: FieldWidthEnum.S },
    { name: 'order', text: 'Ordrenr.', visible: false },
    { name: 'line', text: 'Linje', visible: false, orderBy: 'Ascending', orderByPriority: 1 },
    { name: 'type', text: 'Type', foreignKeyTableName: 'SalesOrderLineType', foreignKeyField: 'id', foreignKeyValue: 'name', width: '80px' },
    { name: 'product', text: 'Varenr.', width: '100px' },
    { name: 'category', text: 'Kategori', visible: false },
    { name: 'name', text: 'Beskrivelse', width: '160px' },
    { name: 'salesQty', text: 'Antal', width: '80px', format: 'Number', footerType: 'Sum' },
    { name: 'salesInventQty', text: 'Antal indeholdt', width: '80px', format: 'Number', footerType: 'Sum' },
    { name: 'deliveredQty', text: 'Antal leveret', width: '80px', format: 'Number', footerType: 'Sum', visible: false },
    { name: 'invoicedQty', text: 'Antal faktureret', width: '80px', format: 'Number', footerType: 'Sum', visible: false },
    { name: 'salesPrice', text: 'Salgspris', width: '80px', format: 'Amount' },
    { name: 'amountCurDiscount', text: 'Rabat', format: 'Amount', footerType: 'Sum', visible: false },
    { name: 'amountCurExTax', text: 'Beløb eks. moms', format: 'Amount', footerType: 'Sum' },
    { name: 'amountCurTax', text: 'Momsbeløb', format: 'Amount', visible: false, footerType: 'Sum' },
    { name: 'amountCur', text: 'Beløb inkl. moms', format: 'Amount', footerType: 'Sum' },
    { name: 'status', text: 'Status', tooltipText: 'Linjestatus', foreignKeyTableName: 'SalesOrderStatus', foreignKeyField: 'id', foreignKeyValue: 'name', foreignKeySortFields: ['sorting'] },
    { name: 'createdJobId', text: 'Job oprettet', format: 'Integer', visible: false },
    { name: 'modifiedJobId', text: 'Job rettet', format: 'Integer', visible: false }
  ];

  public orderToolbarCommandsGet(isCaptureReady: boolean, isInvoiceCreditReady: boolean): UiCommand[] {
    var items: UiCommand[] = this.orderToolbarCommands;
    if (isCaptureReady) {
      items.unshift(uiCmdSalesOrderCaptureRows, uiCmdSeparator);
    }
    if (isInvoiceCreditReady) {
      items.unshift(uiCmdSalesOrderInvoiceReleaseRows, uiCmdSeparator);
    }
    return items;
  }

  public orderContextMenuCommandsGet(isCaptureReady: boolean, isInvoiceCreditReady: boolean): UiCommand[] {
    var items: UiCommand[] = this.orderContextMenuCommands;
    if (isCaptureReady) {
      items.unshift(uiCmdSalesOrderCaptureRow, uiCmdSeparator);
    }
    if (isInvoiceCreditReady) {
      items.unshift(uiCmdSalesOrderInvoiceReleaseRow, uiCmdSeparator);
    }
    return items;
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case uiCmdOrderSyncAll.id:
        this.syncSalesOrders(null, event);
        break;
      case uiCmdSalesOrderCaptureRow.id:
        this.salesOrderCapture(numsFromEventRowDataGet(event),event);
        break;
      case uiCmdSalesOrderCaptureRows.id:
        this.salesOrderCapture(numsFromEventRowsDataGet(event),event);
        break;
      case uiCmdOrderSyncSelectedRow.id:
        this.syncSalesOrder(event);
        break;
    }
  }

  // Sync
  private syncSalesOrder(event: UiCommandEvent) {
    var salesOrderNum = numGetFromEventRowData(event);
    if (salesOrderNum != null) {
      this.syncSalesOrders(salesOrderNum, event);
    }
  }

  private syncSalesOrders(salesOrderNum: string, event: UiCommandEvent) {
    var snackBarRef = this.dialog.snackBar('Henter nye ordrer fra salgskanaler', 'Vent venligst...', 10000);
    this.routeService.isLoading$.next(true);
    (salesOrderNum != null ? this.client.syncOrder(salesOrderNum) : this.client.syncOrders())
      .subscribe({
        next: result => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          refreshEvent(event);
        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.ui.handleApiStatusMessage(error);
        }
      });
  }

  private salesOrderCapture(nums: string[], event: UiCommandEvent) {
    if (nums?.length > 0) {
      var snackBarRef = this.dialog.snackBar('Indløser ordre betalinger', 'Vent venligst...', 10000);
      this.routeService.isLoading$.next(true);
      this.client.salesOrdersPayment(new SalesOrdersPaymentCommand({ capture: true, orderNums: nums }))
        .subscribe({
          next: result => {
            console.log('salesOrderCapture', result);
            this.routeService.isLoading$.next(false);
            snackBarRef.dismiss();
            refreshEvent(event);
          },
          error: (error) => {
            this.routeService.isLoading$.next(false);
            snackBarRef.dismiss();
            this.ui.handleApiStatusMessage(error);
          }
        });
    }
    else {
      this.dialog.snackBarError('Ingen ordrer markeret');
    }
  }
}

function refreshEvent(event: UiCommandEvent) {
  if (event?.appComponent != null) {
    refreshComponent(event.appComponent);
  }
}

function refreshComponent(appComponent: Object) {
  if (appComponent != null && appComponent.hasOwnProperty('research')) {
    (<any>appComponent).research();
  }
}

function numGetFromEventRowData(event: UiCommandEvent): string {
  return event?.rowData?.num;
}

function numsFromEventRowDataGet(event: UiCommandEvent): string[] {
  var num: string = numGetFromEventRowData(event)
  return num ? [num] : [];
}

function numsFromEventRowsDataGet(event: UiCommandEvent): string[] {
  var nums: string[] = [];
  if (event?.appComponent != null) {
    var appComponent: CerGridComponent = <CerGridComponent>event.appComponent;
    if (appComponent) {

      appComponent.rowSelectedRowsData().forEach(rowData => {
        var dto : any = <any>rowData;
        if (dto?.num) {
          nums.push(dto.num);
        }
      });
    }
  }
  return nums;
}
