import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FieldMetadata, FieldWidthEnum, KeyValue, ViewMetadata } from "src/cer/cer-data/cer-data.service";
import { UiCommandEvent } from "src/cer/cer-grid/cer-grid-command.service";
import { CerGridComponent } from "src/cer/cer-grid/cer-grid.component";

@Injectable({ providedIn: 'root' })
export class CerDataNoteService implements OnInit, OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  private viewMetadata: ViewMetadata = {
    name: 'noter', dataApiName: 'DataNote', text: 'Noter', textSingular: 'Note',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'row', titleFields: ['txt'],
    allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['id', 'history']
  };

  public fieldMetadata: FieldMetadata[] = [
    {
      name: 'type', text: 'Type', foreignKeyTableName: 'DataNoteType', widthType: FieldWidthEnum.XS, orderBy: 'Ascending', orderByPriority: 1,
      defaultValue: 1, allowEdit: true, allowEditOnCreate: false
    },
    { name: 'text', text: 'Beskrivelse', widthType: FieldWidthEnum.L },
    {
      name: 'created', text: 'Oprettet', format: 'DateLong', widthType: FieldWidthEnum.XXS, orderBy: 'Descending', orderByPriority: 2,
      defaultValue: new Date(), allowEdit: false, allowEditOnCreate: false
    },
    { name: 'row', text: 'Tabel post', widthType: FieldWidthEnum.XS, allowEdit: false, allowEditOnCreate: false, visible: false },
    { name: 'table', text: 'Tabel', widthType: FieldWidthEnum.XS, allowEdit: false, allowEditOnCreate: false, visible: false },
    { name: 'tableName', text: 'Tabel', widthType: FieldWidthEnum.XS, allowEdit: false, allowEditOnCreate: false, visible: false },
  ];

  private dataNoteGrid: CerGridComponent;
  public setDataNoteGrid: CerGridComponent;
  Grid(grid: CerGridComponent) {
    this.dataNoteGrid = grid;
    this.subscriptionManager$.add(this.dataNoteGrid.commmand.subscribe(event => this.onCommand(event)));
  }
  private onCommand(event: UiCommandEvent): void {
    /*
    if (event.actionType == UiActionTypeEnum.Add && event.source == UiCommandSourceEnum.ActionBegin) {
    }
    */
  }

  public viewMetadataGet(dataLinkTables: string[]): ViewMetadata {
    var view: ViewMetadata = Object.assign({}, this.viewMetadata);

    if (dataLinkTables != null && dataLinkTables.length > 0) {
      var dataApiParams: KeyValue[] = [];

      var n = 1;
      dataLinkTables.forEach((tableName) => {
        var keyNum: string = (n == 1 ? '' : n.toString());
        dataApiParams.push({ key: '$dataLink' + keyNum + 'Table', value: tableName });
        n++;
      });

      view.dataApiParams = dataApiParams;
    }
    return view;
  }

  public fieldMetadataGet(defaultTableName: string): FieldMetadata[] {
    var fieldMetadata: FieldMetadata[] = [];
    this.fieldMetadata.forEach((field) => {
      if (field.name == 'tableName') {
        field.defaultValue = defaultTableName;
      }
      fieldMetadata.push(Object.assign({}, field));
    });
    return fieldMetadata;
  }
}