import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, ChangeDetectionStrategy, Injector } from '@angular/core'
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { UploadingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { ClickEventArgs, Toolbar } from '@syncfusion/ej2-angular-navigations';
import { LinkedAttachmentDto as Attachment, ChatDto } from "../../app/api";
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { CerAppChatComponent } from '../cer-app-chat/cer-app-chat.component';
import { UiCommand, UiKeyboardShortcut } from '../../cer/cer-grid/cer-grid-command.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CerFormService } from 'src/cer/cer-form/cer-form.service';
import { Subscription } from 'rxjs';
import { EmitType } from '@syncfusion/ej2-base';

export const fileViewerCmdZoomIn: UiCommand = { id: 'fileViewerCmdZoomIn', text: 'Reducer detaljer' };
export const fileViewerCmdZoomOut: UiCommand = { id: 'fileViewerCmdZoomOut', text: 'Udvid detaljer' };

export const fileViewerShortcutZoomIn: UiKeyboardShortcut = { cmd: fileViewerCmdZoomIn, code: 'NumpadAdd', alt: true, ctrl: false, shift: false, enabled: true }; // Alt +
export const fileViewerShortcutZoomOut: UiKeyboardShortcut = { cmd: fileViewerCmdZoomOut, code: 'NumpadSubtract', alt: true, ctrl: false, shift: false, enabled: true }; // Alt -

@Component({
  selector: 'cer-app-file-viewer-tab',
  templateUrl: './cer-app-file-viewer-tab.component.html',
  styleUrls: ['./cer-app-file-viewer-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CerAppFileViewerTabComponent {
  @Input() attachments: Attachment[];
  @Input() enableChat: boolean;
  @Input() enableClose: boolean = true;
  @Input() chats: ChatDto[];
  @Input() refTableId: number;
  @Input() refRowId: number;
  @Input() token: string;
  @Input() saveUrl: string;
  @Input() removeUrl: string;
  @Input() resized: boolean;
  @Input() tabCheckBoxAllowed: boolean = false;
  @Input() tabSelectedIndexInitial: number;
  @Input() uploadIsActive: boolean = false;
  @Input() formServiceActive: boolean = true;
  @Input() privateNoteActive: boolean = false;

  @Output() fileUploadChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() tabCheckBoxApply: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() tabSelectedIndexChanged: EventEmitter<number> = new EventEmitter<number>();

  private subscriptionManager$: Subscription = new Subscription();

  constructor(private injector: Injector) {
  }

  // Contenttype
  private contentTypesBody: string[] = ['text/html', 'text/plain']
  private contentTypesImage: string[] = ['image/jpeg', 'image/png', 'image/gif']
  private contentTypesPdf: string[] = ['application/pdf']

  //
  public tabCheckBoxModePossible: boolean = false;
  public tabCheckBoxMode: boolean = false;
  public tabChecked: boolean[] = null;
  public uploadDropAreaElement: HTMLElement;
  public uploadPath: Object;
  public zoomStyle: string = '';
  private zoomFactor: number = 1.0;

  public refRowIdCurr: number = null;


  // Toolbar
  protected attachmentUrlOriginal: string;
  protected attachmentUrlOriginalClass: string;
  protected attachmentUrlDisplay: string;
  protected attachmentUrlDisplayClass: string;
  private attachmentCanZoom: boolean = false;

  private toolbarInstance: Toolbar;
  @ViewChild('toolbar', { static: false }) set toolbarContent(toolbarInstance: Toolbar) {
    if (toolbarInstance && !this.toolbarInstance) {
      this.toolbarInstance = toolbarInstance;
    }
  }

  // Tabs
  private tabGroup: MatTabGroup;
  protected selectedTabIndex: number = 0;
  @ViewChild('tabGroup', { static: false }) set tabGroupContent(tabGroup: MatTabGroup) {
    if (tabGroup && !this.tabGroup) {
      this.tabGroup = tabGroup;
    }
  }

  private pdfViewer: PdfViewerComponent;
  @ViewChild('pdfViewer', { static: false }) set pdfViewerContent(pdfViewer: PdfViewerComponent) {
    if (pdfViewer && !this.pdfViewer) {
      this.pdfViewer = pdfViewer;
    }
  }

  @ViewChild(CerAppChatComponent) chatViewer: CerAppChatComponent;


  ngOnInit() {

    this.uploadDropAreaElement = document.getElementById('uploadDropArea');
    this.tabSelectedIndexChanged.subscribe(idx => this.setAttachmentOpenUrl(idx));
    if (this.formServiceActive) {
      var formService: CerFormService = <CerFormService>this.injector.get(CerFormService);
      //this.subscriptionManager$.add(formService.formCommand$.subscribe(formCommandArgs => this.onFormCommand(formCommandArgs)));
      this.subscriptionManager$.add(formService.parentData$.subscribe(parentDataArgs => this.onParentData(parentDataArgs)));
    }
  }

  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['attachments']) {
      this.tabCheckedRefresh();
      this.setAttachmentOpenUrl(this.tabGroup?.selectedIndex);
    }
    if (changes['tabSelectedIndexInitial']) {
      if (this.tabSelectedIndexInitial != null) {
        this.tabSelectIndex(this.tabSelectedIndexInitial);
      }
    }

    this.toolbarRefresh();

    this.uploadPath = {
      saveUrl: this.saveUrl,
      removeUrl: this.removeUrl
    };
  }

  private onParentData(parentDataArgs: any) {
    if (!parentDataArgs.row && parentDataArgs.stable) {
      // Force reset (to-do: make this the primary input parameter event)
      /*
      this.token = null;
      this.refRowId = null;
      this.refTableId = null;
      this.attachments = [];
      this.chats = [];
      */
    }
  }

  private setAttachmentOpenUrl(idx: number) {
    var attachment: Attachment = null;
    if (this.attachments && this.attachments.length > idx) {
      attachment = this.attachments[idx];
    }

    this.attachmentUrlOriginal = attachment?.originalUrl?.length > 0 ? attachment?.originalUrl : attachment?.url;
    this.attachmentUrlOriginalClass = this.attachmentUrlOriginal?.length > 0 ? "" : "display-none";
    this.attachmentUrlDisplay = attachment?.originalUrl?.length > 0 ? attachment?.url : null;
    this.attachmentUrlDisplayClass = this.attachmentUrlDisplay?.length > 0 ? "" : "display-none";
    this.attachmentCanZoom = (attachment != null); // this.contentIsPdf(attachment);
    this.zoomStyle = '';
    this.zoomFactor = 1.0;
    this.toolbarRefresh();
  }

  private tabCheckedRefresh() {
    var maxIdx: number = 0;
    if (this.attachments) {
      this.attachments.forEach(a => maxIdx = Math.max(maxIdx, a.attachmentIndex));
      this.tabChecked = new Array(maxIdx);
      var i: number = 0;
      this.attachments.forEach(a => { this.tabChecked[a.attachmentIndex] = (i > 0); i++; });
    }

    if (this.refRowId != this.refRowIdCurr) {
      this.refRowIdCurr = this.refRowId;
      this.tabCheckBoxMode = false;
    }

    this.tabCheckBoxModePossible = this.tabCheckBoxAllowed && this.attachments && this.attachments.length > 2;
  }

  public toolbarRefresh() {

    if (this.toolbarInstance) {
      var hasAttachments: boolean = this.attachments?.length > 0;
      var t: Toolbar = this.toolbarInstance;

      if ((<any>t).tbarEle) {
        t.hideItem(0, !(this.saveUrl?.length > 0 && this.token != null && this.refRowId != null)); // No upload
        t.hideItem(1, !(hasAttachments && this.attachmentUrlOriginal?.length > 0));
        t.hideItem(2, !(hasAttachments && this.attachmentUrlDisplay?.length > 0));
        t.hideItem(3, !(hasAttachments && this.attachmentCanZoom));
        t.hideItem(4, !(hasAttachments && this.attachmentCanZoom));
        t.hideItem(5, !(this.tabCheckBoxModePossible && !this.tabCheckBoxMode));
        t.hideItem(6, !(this.tabCheckBoxModePossible && this.tabCheckBoxMode));
        t.hideItem(7, !this.enableClose);  // No close
      }
    }
  }

  public resize() {
    if (this.contentIsPdf(this.attachments[this.tabSelectedIndexInitial])) {
      if (this.pdfViewer) {
        this.pdfViewer.zoom = 1.0;
        this.pdfViewer.afterLoadComplete
        this.pdfUpdateSize();
      }
    }
    else {
      this.zoomFactor = 1.0;
      this.zoomStyle = '';
    }
  }

  private zoomIn() {
    if (this.contentIsPdf(this.attachments[this.tabSelectedIndexInitial])) {
      this.pdfZoomIn();
    }
    else {
      this.zoomFactor += 0.1;
      this.zoomStyleCalc();
    }
  }

  private zoomOut() {
    if (this.contentIsPdf(this.attachments[this.tabSelectedIndexInitial])) {
      this.pdfZoomOut();
    }
    else {
      this.zoomFactor -= 0.1;
      if (this.zoomFactor < 0.1) {
        this.zoomFactor = 0.1;
      }
      this.zoomStyleCalc();
    }
  }

  private zoomStyleCalc() {
    if (this.zoomFactor == 1.0) {
      this.zoomStyle = '';
    }
    else {
      this.zoomStyle = 'transform: scale(' + this.zoomFactor + '); transform-origin: 0% 0%;';
    }
  }

  contentIsBody(attachment: Attachment): boolean {
    var isBody: boolean = false

    if (attachment != null && this.contentTypesBody.includes(attachment.contentType)) {
      isBody = true
    }

    return isBody
  }

  contentIsPdf(attachment: Attachment): boolean {
    return attachment != null && this.contentTypesPdf.includes(attachment.contentType)
  }

  contentIsImage(attachment: Attachment): boolean {
    return attachment != null && this.contentTypesImage.includes(attachment.contentType)
  }

  contentIsDefault(attachment: Attachment): boolean {
    return attachment != null && !(this.contentIsBody(attachment) ||
      this.contentIsPdf(attachment) ||
      this.contentIsImage(attachment))
  }

  public toolbarClick(args: ClickEventArgs) {
    //console.log("Toolbar id: " + args.item.id);
    switch (args.item.id) {
      case 'uploadShowHide':
        this.uploadShowHide();
        break;
      case 'openAttachmentInNewTab':
        this.openAttachInNewTab(true);
        break;
      case 'openAttachmentOriginalInNewTab':
        this.openAttachInNewTab(false);
        break;
      case 'zoomIn':
        this.zoomIn();
        break;
      case 'zoomOut':
        this.zoomOut();
        break;
      case 'tabCheckboxShow':
        this.tabCheckboxShow();
        break;
      case 'tabCheckboxApply':
        this.tabCheckboxApply();
        break;
      case 'close':
        this.close();
        break;
    }
  }

  public toolbarCreated(args: any) {
    this.toolbarRefresh();
  }

  public tabCheckboxShow() {
    this.tabCheckBoxMode = true;
    this.toolbarRefresh();
  }

  public tabCheckboxSet(attachment: Attachment, event: MatCheckboxChange) {
    this.tabChecked[attachment.attachmentIndex] = event.checked;
  }

  public tabCheckboxApply() {
    this.tabCheckBoxMode = false;
    var fileNames: string[] = [];
    this.attachments.forEach(a => { if (this.tabChecked[a.attachmentIndex]) { fileNames.push(a.fileName + a.fileExtension); } });
    this.tabCheckBoxApply.emit(fileNames);
    this.toolbarRefresh();
  }

  public uploadShowHide() {
    this.uploadIsActive = (!this.uploadIsActive && this.token != null && this.refRowId != null);
  }

  // Open file in new tab
  public openAttachInNewTab(original: boolean) {
    if (this.tabGroup != null) {
      var idx: number = this.tabSelectedIndexInitial;
      if (this.attachments != null && this.attachments.length > idx) {
        var attachment: Attachment = this.attachments[idx];
        if (attachment) {
          var url: string = original ? attachment.originalUrl : attachment.url;
          if (url?.length > 0) {
            window.open(url, '_blank');
          }
        }
      }
    }
  }

  // Tabs
  public tabSelectIndex(idx: number) {
    if (this.selectedTabIndex != idx && idx >= 0) {
      this.selectedTabIndex = idx;
      this.tabSelectedIndexChanged.emit(idx);
    }
  }

  public onSelectedTabChanged(event: MatTabChangeEvent) {
    this.tabSelectedIndexChanged.emit(event.index);
    var tabGroup: any = this.tabGroup;
    var tabLen: number = tabGroup?._closestTabGroup?._allTabs?.length ?? 0;
    var tabChatIsSelected: boolean = tabLen > 0 && event.index === tabLen - 1;
    if (tabChatIsSelected) {
      this.chatViewerSetFocus();
    }
    if (this.enableChat && this.chatViewer && tabChatIsSelected) {
      this.chatViewer.checkMarkRead();
      this.chatViewerSetFocus();
    }
  }

  private chatViewerSetFocus() {
    if (this.chatViewer) {
      this.chatViewer.refresh();

      if (this.tabsHasFocus()) {
        this.chatViewer.setFocus();
        this.chatViewer.scrollToBottom();
      }
    }
  }

  private tabsHasFocus(): boolean {
    var tabGroupElement: any = (this.tabGroup as any)?._closestTabGroup;
    var focusElement: Element = document.activeElement;
    if (tabGroupElement && focusElement) {
      return (focusElement == tabGroupElement || tabGroupElement?._elementRef?.nativeElement?.contains(focusElement));
    }
    return false;
  }

  // Chat
  public chatOpen(idx: number = this.attachments?.length ?? 0) {
    if (idx != this.tabSelectedIndexInitial) {
      this.tabSelectedIndexInitial = idx;
    }
    if (this.tabGroup) {
      var tabGroupElement: any = (this.tabGroup as any)?._closestTabGroup;
      if (tabGroupElement && tabGroupElement.selectedIndex != idx) {
        this.tabGroup.selectedIndex = idx;
        tabGroupElement.selectedIndex = idx;
      }
    }
  }

  // Zoom
  public pdfUpdateSize() {
    var _pdfViewer: any = <any>this.pdfViewer;
    if (_pdfViewer._pdf) {
      this.pdfViewer.updateSize();
    }
  }

  public pdfZoomIn() {
    if (this.pdfViewer) {
      if (!this.pdfViewer.zoom) {
        this.pdfViewer.zoom = 1.25;
      }
      else {
        this.pdfViewer.zoom = this.pdfViewer.zoom + 0.25;
      }
      this.pdfUpdateSize();
    }
  }

  public pdfZoomOut() {
    if (this.pdfViewer) {
      if (!this.pdfViewer.zoom) {
        this.pdfViewer.zoom = 0.75;
      }
      else if (this.pdfViewer.zoom > 0.25) {
        this.pdfViewer.zoom = this.pdfViewer.zoom - 0.25;
      }
      this.pdfUpdateSize();
    }
  }

  public nextTab() {
    if (this.tabGroup && this.tabSelectedIndexInitial + 1 < this.attachments.length) {
      this.tabSelectedIndexInitial++;
    }
  }

  public prevTab() {
    if (this.tabGroup && this.tabSelectedIndexInitial > 0) {
      this.tabSelectedIndexInitial--;
    }
  }

  public close() {
    this.fileUploadChanged.emit("Close");
  }

  // Uploader
  public onUploadSuccess(args: any): void {
    //    console.log(args.operation);

    if (args.operation === 'upload') {
      console.log('File uploaded successfully');
      this.fileUploadChanged.emit("Add");
    }
  }


  public onUploading: EmitType<UploadingEventArgs> = (args: any) => {
    args.customFormData = [{ 'id': this.refRowId }, { 'linktype': this.refTableId }, { 'token': this.token }];
  }

  // Chat
  public refreshChat() {
    if (this.enableChat && this.chatViewer) {
      this.chatViewer.refresh();
    }
  }
}
