<cer-form *ngIf="setupComplete" #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid #grid [dataParent]="true"></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-app-file-viewer-tab #tabAttachments [token]="attachmentToken" [refTableId]="1" [refRowId]="attachmentRefRowId"
      [attachments]="attachments" [enableChat]="true" [chats]="chats" [saveUrl]="attachmentSaveUrl"
      [removeUrl]="attachmentRemoveUrl"
      [tabCheckBoxAllowed]="true"
      (tabSelectedIndexChanged)="onAttachmentTabSelectedIndexChanged($event)"
      (fileUploadChanged)="attachmentService.onUploadChange($event)"
      (tabCheckBoxApply)="onAttachmentCheckBoxApply($event)">
    </cer-app-file-viewer-tab>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid #gridPosting [dataChild]="true" selectionInitial="none" >
    </cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #gridCustInvoice [viewMetadata]="invoiceViewMetadata" [dataChild]="true" [fieldMetadata]="invoiceFieldMetadata" selectionInitial="none">
    </cer-grid>
  </ng-template>
</cer-form>