<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid #grid [dataParent]="true"></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-app-file-viewer-tab #tabAttachments
        [token]="attachmentToken"
        [refTableId]="1" 
        [refRowId]="attachmentRefRowId"
        [attachments]="attachments"
        [enableChat]="true" [chats]="chats"
        [saveUrl]="attachmentSaveUrl"
        [removeUrl]="attachmentRemoveUrl"        
        [tabCheckBoxAllowed]="true"
        [privateNoteActive]="true"
        (tabSelectedIndexChanged)="onAttachmentTabSelectedIndexChanged($event)"
        (fileUploadChanged)="attachmentService.onUploadChange($event)"
        (tabCheckBoxApply)="onAttachmentCheckBoxApply($event)">
      </cer-app-file-viewer-tab>
  </ng-template>
</cer-form>
