import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

import { SplitterComponent } from "@syncfusion/ej2-angular-layouts";

import { UiActionTypeEnum, UiCommand, UiCommandEvent, UiKeyboardShortcut } from "../cer-grid/cer-grid-command.service";
import { CerFormService } from "./cer-form.service";
import { extend } from "@syncfusion/ej2-base/src/util";

export const uiCmdPaneRightToggle: UiCommand =
   { text: 'Vis detaljer', tooltipText: 'Vis detalje panel', id: 'paneRightToggle', align: 'Right', iconCss: 'e-menu-icon e-icons-org e-chevron-left-fill-3', toolbarShowTextOn: "Overflow" };
export const uiCmdPaneRightToggleNoText: UiCommand =
   { text: '', tooltipText: 'Vis detalje panel', id: 'paneRightToggle', align: 'Right', iconCss: 'e-menu-icon e-icons-org e-chevron-left-fill-3', toolbarShowTextOn: "Overflow" };
export const uiCmdMainToggle: UiCommand =
   { text: 'Vis midterste', tooltipText: 'Vis miderste panel', id: 'paneMainToggle', align: 'Right', iconCss: 'e-menu-icon e-icons-org e-chevron-up-fill-3', toolbarShowTextOn: "Overflow" };
export const uiCmdBottomToggle: UiCommand =
   { text: 'Vis nederste', tooltipText: 'Vis nederste panel', id: 'paneBottomToggle', align: 'Right', iconCss: 'e-menu-icon e-icons-org e-chevron-up-fill-3', toolbarShowTextOn: "Overflow" };
export const uiCmdPaneBottomToggleNoText: UiCommand =
   { text: '', tooltipText: 'Vis nederste', id: 'paneBottomToggle', align: 'Right', iconCss: 'e-menu-icon e-icons-org e-chevron-up-fill-3', toolbarShowTextOn: "Overflow" };
export const uiCmdPaneRightShow: UiCommand =
   { text: '', tooltipText: 'Vis detalje panel', id: 'detailShow', align: 'Left', iconCss: 'e-menu-icon e-icons e-open' };
export const uiCmdPaneRightClose: UiCommand =
   { text: '', tooltipText: 'Luk detalje panel', id: 'paneRightRightClose', align: 'Left', iconCss: 'e-menu-icon e-icons e-close' };
export const uiCmdBottomShow: UiCommand =
   { text: '', tooltipText: 'Vis nederste panel', id: 'bottomShow', align: 'Left', iconCss: 'e-menu-icon e-icons e-open' };
export const uiCmdBottomClose: UiCommand =
   { text: '', tooltipText: 'Luk nederste panel', id: 'paneBottomClose', align: 'Left', iconCss: 'e-menu-icon e-icons e-close' };
export const uiCmdMainClose: UiCommand =
   { text: '', tooltipText: 'Luk miderste panel', id: 'paneMainClose', align: 'Left', iconCss: 'e-menu-icon e-icons e-close' };

export const uiCmdPaneRightReduce: UiCommand = { id: 'uiCmdPaneRightReduce', text: 'Reducer detaljer' };
export const uiCmdPaneRightEnlarge: UiCommand = { id: 'uiCmdPaneRightEnlarge', text: 'Udvid detaljer' };

export const uiShortcutPaneRightReduce: UiKeyboardShortcut = { cmd: uiCmdPaneRightReduce, code: 'PageUp', alt: true, ctrl: false, shift: true, enabled: true }; // Alt+Shift+PgUp
export const uiShortcutPaneRightEnlarge: UiKeyboardShortcut = { cmd: uiCmdPaneRightEnlarge, code: 'PageDown', alt: true, ctrl: false, shift: true, enabled: true }; // Alt+Shift+PgDn

const formPaneNames = ['main', 'left', 'right', 'top', 'bottom'] as const;
export type CerFormPane = typeof formPaneNames[number];
export type CerFormPaneVisible = 'show' | 'collapse' | 'hide';

export class CerFormPaneSettings {
   visible?: CerFormPaneVisible = 'show';
   visibleWhenClose?: CerFormPaneVisible = 'collapse';
   size?: string;
   sizeSecondary?: string;
   splitter?: SplitterComponent;
   splitterIndex?: number;
}

export class CerFormPanesSettings {
   main?: CerFormPaneSettings = { visible: 'show' };
   left?: CerFormPaneSettings;
   right?: CerFormPaneSettings;
   top?: CerFormPaneSettings;
   bottom?: CerFormPaneSettings;
   orientationPrimary?: 'horizontal' | 'vertical' = 'vertical';
   //horizontalSplitterWidth?: string = "1px";
   //verticalSplitterHeight?: string = "1px";
}


@Injectable()
export class CerFormPanesService implements OnDestroy {


   constructor(private formService: CerFormService) {
      this.manage(this.formService.formCommand$.subscribe(event => this.onCommand(event)));
      this.manage(this.formService.childCommand$.subscribe(event => this.onCommand(event)));
   }

   private subscriptionManager: Subscription = new Subscription();
   private manage(s: Subscription) {
      this.subscriptionManager.add(s);
   }

   ngOnDestroy() {
      this.subscriptionManager.unsubscribe();
   }

   private panes: CerFormPanesSettings;
   public panesChanged$: BehaviorSubject<CerFormPanesSettings> = new BehaviorSubject<CerFormPanesSettings>(null);
   //private vSplitter: SplitterComponent;
   //private hSplitter: SplitterComponent;

   public static panesDefaulting(p: CerFormPanesSettings = null): CerFormPanesSettings {
      var pDefault: CerFormPanesSettings = new CerFormPanesSettings();
      var pMerged: CerFormPanesSettings = extend(pDefault, p);

      formPaneNames.forEach(paneName => {
         if (pMerged[paneName] != null) {
            var nDefault: CerFormPaneSettings = new CerFormPaneSettings();
            pMerged[paneName] = extend(nDefault, pMerged[paneName]);
            //console.log(paneName, pMerged[paneName]);
         }
      });

      return pMerged;
   }

   public panesInit(panes: CerFormPanesSettings) {
      this.panes = CerFormPanesService.panesDefaulting(panes);
      this.panesChanged$.next(this.panes);
   }

   public panesGet(): CerFormPanesSettings {
      return this.panes;
   }

   public vSplitterLink(vSplitter: SplitterComponent, panes: CerFormPanesSettings) {
      //this.vSplitter = vSplitter;
      this.panes = panes;
      if (this.panes) {
         var idxNext: number = 0;
         idxNext = this.splitterLinkPane('top', vSplitter, idxNext);
         idxNext = this.splitterLinkPane('main', vSplitter, idxNext, panes.orientationPrimary == 'horizontal' || (this.panes.left == null && this.panes.right == null));
         idxNext = this.splitterLinkPane('bottom', vSplitter, idxNext);
         if (idxNext > 0) {
            this.panesChanged$.next(this.panes);
         }
      }
   }

   public hSplitterLink(hSplitter: SplitterComponent, panes: CerFormPanesSettings) {
      //this.hSplitter = hSplitter;
      this.panes = panes;
      if (this.panes) {
         var idxNext: number = 0;
         idxNext = this.splitterLinkPane('left', hSplitter, idxNext);
         idxNext = this.splitterLinkPane('main', hSplitter, idxNext, panes.orientationPrimary == 'vertical' || (this.panes.top == null && this.panes.bottom == null));
         idxNext = this.splitterLinkPane('right', hSplitter, idxNext);
         if (idxNext > 0) {
            this.panesChanged$.next(this.panes);
         }
      }
   }

   private splitterLinkPane(pane: CerFormPane, splitter: SplitterComponent, idxNext: number, doMainLink: boolean = null): number {
      var s: CerFormPaneSettings = this.panes[pane];
      if (s != null && s.visible != 'hide') {
         if (doMainLink === true || pane != 'main') {
            this.panes[pane].splitter = splitter;
            this.panes[pane].splitterIndex = idxNext;
         }
         idxNext++;
      }
      return idxNext;
   }

   public onHSplitterResizing(args: any) {
      this.formDesignChangeNext(UiActionTypeEnum.Splitter, args);
   }

   public onVSplitterResizing(args: any) {
      this.formDesignChangeNext(UiActionTypeEnum.Splitter, args);
   }

   public onSplitterPaneState(args: any) {
      var splitter: SplitterComponent = this.splitterFromElement(args.element);
      var index: any = args.index;
      if (index) {
         if (Array.isArray(index) && index.length > 0) {
            index.forEach(i => this.visibleSetFromPaneObj(splitter, i));
         }
         else {
            this.visibleSetFromPaneObj(splitter, index);
         }
      }
      this.panesChanged$.next(this.panes);
      this.formDesignChangeNext(UiActionTypeEnum.Splitter, args);
   }

   private visibleSetFromPaneObj(splitter: SplitterComponent, index: number) {
      var pane: CerFormPane = this.paneFromSplitterIndex(splitter, index);
      if (pane) {
         var settings: CerFormPaneSettings = this.panes[pane];
         if (splitter?.paneSettings[index]) {
            var visible: CerFormPaneVisible = (splitter as any).allPanes[index].classList.contains('e-collapsed') ? 'collapse' : 'show';
            if (settings.visible != visible) {
               settings.visible = visible;
            }
         }
      }
   }

   private paneObjectSetState(pane: CerFormPane, expand: boolean, settings: CerFormPaneSettings = null): boolean {
      var changed: boolean = false;
      var settings: CerFormPaneSettings = this.panes[pane];
      if (settings) {
         var splitter = settings.splitter;
         var splitterIndex = settings.splitterIndex;
         if (splitter && splitterIndex >= 0) {
            if (expand) {
               if (splitter.paneSettings[splitterIndex].collapsed) {
                  changed = true;
                  splitter.expand(splitterIndex);
               }
            }
            else {
               if (!splitter.paneSettings[splitterIndex].collapsed) {
                  splitter.collapse(splitterIndex);
                  changed = true;
               }
            }
         }
         else {
            changed = true;
         }
      }
      return changed;
   }
   private paneFromSplitterIndex(splitter: SplitterComponent, index: number): CerFormPane {
      var ret: CerFormPane = null;
      if (splitter != null && index >= 0) {
         formPaneNames.forEach(pane => {
            var settings: CerFormPaneSettings = this.panes[pane];
            if (settings?.splitter != null) {
               if (settings.splitter.orientation == splitter.orientation && settings.splitterIndex == index) {
                  ret = pane;
               }
            }
         });
      }
      return ret;
   }


   private splitterFromElement(element: any): SplitterComponent {
      var splitter: SplitterComponent = null;
      element?.ej2_instances?.forEach((obj: any) => {
         if (obj instanceof SplitterComponent) {
            splitter = obj as SplitterComponent;
         }
      });
      return splitter;
   }

   public onHSplitterExpanded(args: any) {
      this.formDesignChangeNext(UiActionTypeEnum.Splitter, args);
   }

   public onVSplitterExpanded(args: any) {
      this.formDesignChangeNext(UiActionTypeEnum.Splitter, args);
   }



   public formDesignChangeNext(action: UiActionTypeEnum, args: any) {
      this.formService.formDesignChangeNext(action, args);
      if (action == UiActionTypeEnum.PanesShow) {
         this.panesChanged$.next(this.panes);
      }
   }

   public onCommand(event: UiCommandEvent) {
      // Child events from grids etc. + form commands
      switch (event.commandId) {
         case uiCmdPaneRightToggle.id:
            this.toggle('right');
            break;
         case uiCmdPaneRightClose.id:
            this.close('right');
            break;
         case uiCmdMainToggle.id:
            this.toggle('main');
            break;
         case uiCmdMainClose.id:
            this.close('main');
            break;
         case uiCmdBottomToggle.id:
            this.toggle('bottom');
            break;
         case uiCmdBottomShow.id:
            this.show('bottom');
            break;
         case uiCmdBottomClose.id:
            this.close('bottom');
            break;
         case uiCmdPaneRightReduce.id:
            this.reduce('right');
            event.cancel = true;
            break;
         case uiCmdPaneRightEnlarge.id:
            this.enlarge('right');
            event.cancel = true;
            break;
      }
   }


   // Handle visible panes
   public visibleSet(pane: CerFormPane, visibleTo: CerFormPaneVisible, settings: CerFormPaneSettings = null) {
      var settings = settings ?? this.panes[pane];
      if (settings) {
         var visibleClose = settings.visibleWhenClose ?? 'collapse'
         if (visibleClose == 'show') {
            if (visibleTo == 'collapse' || visibleTo == 'hide') {
               visibleTo = visibleClose;
            }
         }
         else if (visibleClose == 'collapse') {
            if (visibleTo == 'hide') {
               visibleTo = visibleClose;
            }
         }
         //if (settings.visible == 'disable') {
         //   visibleTo = 'disable';
         //}
         if (visibleTo != settings.visible) {
            var changed: boolean = this.visibleSetDo(pane, visibleTo, settings);
            if (changed) {
               this.formDesignChangeNext(UiActionTypeEnum.PanesShow, null);
            }
         }
      }
   }

   public visibleSetDo(pane: CerFormPane, visibleTo: CerFormPaneVisible, settings: CerFormPaneSettings = null): boolean {
      var changed: boolean = false;
      var settings = /*settings ??*/ this.panes[pane];
      if (settings) {
         switch (visibleTo) {
            case 'show':
               changed = this.paneObjectSetState(pane, true, settings);
               break;
            case 'collapse':
               changed = this.paneObjectSetState(pane, false, settings);
               break;
            case 'hide':
               changed = this.paneObjectSetState(pane, false, settings);
               break;
         }
         settings.visible = visibleTo;
      }
      return changed;
   }

   public visible(pane: CerFormPane, settings: CerFormPaneSettings = null): boolean {
      var settings = settings ?? this.panes[pane];
      if (settings) {
         var visible: CerFormPaneVisible = settings.visible;
         return visible == null ? false : (visible == 'show' || visible == 'collapse');
      }
      return false;
   }

   public close(pane: CerFormPane, settings: CerFormPaneSettings = null) {
      var settings = settings ?? this.panes[pane];
      if (settings) {
         var visibleTo = settings.visibleWhenClose ?? 'collapse';
         this.visibleSet(pane, visibleTo, settings);
      }
   }

   public show(pane: CerFormPane, settings: CerFormPaneSettings = null) {
      settings = settings ?? this.panes[pane];
      this.visibleSet(pane, 'show', settings);
   }

   public toggle(pane: CerFormPane) {
      if (this.panes) {
         var settings = this.panes[pane];
         if (settings) {
            switch (settings.visible) {
               case 'show':
                  this.close(pane, settings);
                  break;
               case 'hide':
               case 'collapse':
                  this.show(pane, settings);
                  break;
            }
         }
      }
   }
   private reduce(pane: CerFormPane) {
      this.resize(pane, -100, true);
   }

   private enlarge(pane: CerFormPane) {
      this.resize(pane, 100, true);
   }

   private resize(pane: CerFormPane, addPx: number, relative: boolean = false) {
      var settings = this.panes[pane];
      if (settings && settings.splitter && settings.splitterIndex >= 0) {
         var paneObj = settings.splitter.paneSettings[settings.splitterIndex];
         var newPx = relative ? Number(paneObj.size.substring(0, paneObj.size.length - 2)) + addPx : addPx;  // To-do use DOM element
         if (newPx <= 0) {
            this.close(pane);
         }
         if (newPx >= 0 && paneObj.collapsed) {
            this.show(pane);
         }
         paneObj.size = newPx + 'px';
         this.formService.formDesignChangeNext(UiActionTypeEnum.PanesShow);
      }
   }

   // Persistence
   private splitterIsResized: boolean = false; // Avoid persistence save unless splitter is resized
   public getPersistence(): any {
      var o: any = new Object();

      /*
      if (this.splitter?.paneSettings && this.splitter.paneSettings[1]) {
         o['collapsed'] = this.splitter.paneSettings[1].collapsed;
         var panesDimensions: any = (this.splitter as any).panesDimensions;
         if (this.splitterIsResized && panesDimensions && panesDimensions.length > 1) {
            o['width'] = panesDimensions[1] + "px";
         }
      }
      */
      //o = this.panes;
      return o;
   }

   public applyPersistence(o: any) {
      if (o) {
         //this.panes = o;
         /*
         if this.hSplitter
         this.hSplitterLink()
            if (this.splitter?.paneSettings && this.splitter.paneSettings[1]) {
            this.splitter.paneSettings[1].collapsed = o['collapsed'];
            if (o['width']) {
               this.splitter.paneSettings[1].size = o['width'];
               this.detailVisibleRefresh(null, UiActionTypeEnum.PanesShow, true);
            }
         }
         */
      }
   }
}